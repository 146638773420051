import React, { useState } from "react";
import css from "./style.module.css"
import NON_TOUCHABLE_STAR from "../../STAR/NON_TOUCHABLE";

const PRODUCT_CARD = (props) => {
    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.imageContainer}>
                    <img src={require("../../../Assets/2898.png")} className={css.image}/>
                </div >
                <div className={css.detailContainer}>
                    <div className={css.detailContainerSection}>
                        <div className={css.detailContainerNav}>
                            <p className={css.itemTitle}>{props.title}</p>
                        </div>
                        <NON_TOUCHABLE_STAR star={props.rating}/>
                        <div className={css.descContainer}>
                            <p className={css.desc}>{props.desc}</p>
                        </div>
                        <div className={css.buttonContainer}>
                            <button className={css.buyBtn}>
                                {props.price}₮
                            </button>
                            <button className={css.buyBtn}>
                                Дэлгэрэнгүй үзэх
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PRODUCT_CARD