import React, { useEffect, useState, useCallback  } from "react";
import css from "./style.module.css"
import { useProduct } from "../../Context/productContext";
import { useCategory } from "../../Context/categoryContext";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import PRODUCT_CARD from "../../Components/CARD/PRODUCT_CARD";


const CATEGORY_PAGE = (props) => {

    const {categories } = useCategory()
    const {products} = useProduct()

    const [isLoading, setIsLoading] = useState(false)
    const [active, setActive] = useState("Бүгд")

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category'); 

    const [product, setProduct] = useState([])


    console.log(category, active)

    const fetchProducts = useCallback(async () => {
        
        setIsLoading(true)
        if (!category || category === null && active === "Бүгд") {
            setProduct(products)
            setTimeout(() => {
                setIsLoading(false)
            },1500)
            return;
        }; 
        try {
         
            const response = await axios.get(`https://omn1group.com/api/v1/product/category/${category}`);
            setProduct(response.data.data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false)
        }
    }, [category, products, active, setProduct]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);
    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.left}>
                    <label className={css.title}>Төрлүүд</label>
                    <div className={css.hr}>

                    </div>
                    
                    <ul className={css.list}>
                            <Link to={"/files"} className={css.link} onClick={fetchProducts}>
                             <li className={css.listItem} onClick={() => {setActive("Бүгд")}} >
                                <div className={css.active}>
                                    {active === "Бүгд" && <img src={require("../../Assets/activeCheck.png")} className={css.activeCheck}/>}
                                </div>
                                <p className={css.name}>Бүх</p>
                            </li>
                            </Link>
                       {
                            categories && categories.length > 0 

                            ? 

                            categories.map((e, i) => (
                            <Link to={`/files?category=${encodeURIComponent(e.name)}`} className={css.link} onClick={fetchProducts}>
                             <li className={css.listItem} key={i} onClick={() => {setActive(e.name)}} >
                                <div className={css.active}>
                                    {active === e.name && <img src={require("../../Assets/activeCheck.png")} className={css.activeCheck}/>}
                                </div>
                                <p className={css.name}>{e.name}</p>
                            </li>
                            </Link>
                            ) )

                            : 

                            "Төрөл байхгүй байна"
                       }
                    </ul>

                </div>
                {
                    isLoading 
                    ? 
                    <div>loading</div>
                    :
                    <div className={css.right}>
                    <div className={css.rightContainer}>
                        {
                            !category  && active === "Бүгд" 
                            ?
                            products.map((e, i) => (
                               
                            <PRODUCT_CARD title={e.product_name} desc={e.short_desc} price={e.price} rating={e.rating}/>
    
                              
                            ))
                            : 

                            product.map((e, i) => (
                                <PRODUCT_CARD title={e.product_name} desc={e.short_desc} price={e.price} rating={e.rating}/>

                            ))
                        }
                    </div>
                </div>  
                }
            </div>
        </div>
    )
}

export default CATEGORY_PAGE