import React, {useState, useEffect} from 'react';
import NAVBAR from '../../Source/NAVBAR';
import './App.css';
import LOGIN from '../../Source/LOGIN';
import BURGER_MENU from '../../Source/BURGER_MENU';
import SHADOW from '../../Components/MAIN_APP/SHADOW';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home';
import FOOTER from '../../Source/FOOTER';
import SUBSCRIPTION from '../SUBSCRIPTION';
import FAVOURITES from '../FAVOURITES';
import CONTENT from '../CONTROLLER_CONTENT';
import PROFILE from '../Profile';
import PRODUCT_DETAIL from '../PRODUCT_DETAIL';
import MESSAGE_MODAL from '../../Source/MESSAGE_MODAL';
import { useMessage } from '../../Context/messageContext';
import CATEGORY_PAGE from '../CATEGORY_PAGE';

function App() {
  const [modal, setModal] = useState(false)
  const [burgerMenu, setBurgerMenu] = useState(false)
  const [modalType, setModalType] = useState("login")

  const {modalMessage} = useMessage()

  const handleResize = () => {
    if (window.innerWidth > 767) {
      setBurgerMenu(false); 
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
    
        <MESSAGE_MODAL/>


        <NAVBAR setModal={setModal} setBurgerMenu={setBurgerMenu}/>
        <LOGIN setModal={setModal} modal={modal} type={modalType} setType={setModalType}/>
        {
          burgerMenu || modalMessage ?  <SHADOW onclick={setBurgerMenu}/>
          :null
        }
        <BURGER_MENU menu={burgerMenu} setBurgerMenu={setBurgerMenu} setLoginType={setModalType} setModal={setModal}/>

        <Routes >
          <Route path='/' element={<Home setModalType={setModalType} setModal={setModal}/>}/>
          <Route path='/subscription' element={<SUBSCRIPTION/>}/>
          <Route path='/favourites' element={<FAVOURITES/>}/>
          <Route path='/content' element={<CONTENT/>}/>
          <Route path='/profile' element={<PROFILE/>}/>
          <Route path='/files' element={<CATEGORY_PAGE/>}/>
          <Route path='/files/:productName' element={<PRODUCT_DETAIL/>}/>
        </Routes>
        <FOOTER/>
    </div>
  );
}

export default App;
