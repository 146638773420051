import React from "react";
import css from "./style.module.css"
import TOP_NAV from "../../Components/NAVBAR/1.TOP_NAV";

const NAVBAR = (props) => {
    return (
        <div className={css.containerFluid}>
                <TOP_NAV setModal={props.setModal} setBurgerMenu={props.setBurgerMenu}/>
        </div>
    )
}

export default NAVBAR