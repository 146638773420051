import React from "react";
import css from "./style.module.css"

const PRODUCT_DETAIL = (props) => {
    

    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.block}>

                </div>
                <div className={css.containerItem}>
                   <div className={css.containerItemSection}>

                   </div>
                   <div className={css.containerBanner}>

                   </div>
                </div>  
            </div>
        </div>
    )

}

export default PRODUCT_DETAIL