import React, { useState } from "react";
import css from "./style.module.css"
import user from "../../../Assets/user.png"
import emailIcon from "../../../Assets/email.png"
import passwordIcon from "../../../Assets/password.png"
import exit from "../../../Assets/exit.png"
import axios from "axios";
import LOADING from "../../LOADING";
import { useMessage } from "../../../Context/messageContext";

const CONTAINER_REGISTER = (props) => {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isLoading , setIsLoading] = useState(false)

    const [errorMessage , setErrorMessage] = useState("")

    const [token, setToken] = useState("")
    const {setModalMessage} = useMessage()


    const handleRegister = async () => {
        try 
        {
            const response = await axios.post("https://omn1group.com/api/v1/auth/v1/register", {username, email , password , confirmPassword})
            if(response.status === 200)
            {
                setIsLoading(true)
                props.setModal(false)
                setTimeout(() => {
                    setIsLoading(false)
                    setModalMessage("Хэрэглэгчийн бүртгэл амжилттай үүслээ.")
                    
                },2000)
            }
        }
        catch(err)
        {
            setErrorMessage(err.response.data.message)
        }
    } 


    const handleToken = async () => {
            try {
                const response = await axios.post(
                    "https://merchant.qpay.mn/v2/auth/token", 
                    {},
                    {
                        headers: {
                            'Authorization': 'Basic ' + btoa('BOXTECH_MN' + ':' + 'SW5jzYKU')
                        }
                    }
                );
                console.log(" TOKEN AWAH",response.data);
                setToken(response.data.access_token)

            } catch (err) {
                console.log("Error:", err);
            }
    };

    console.log("awsanToken" , token)

    const [invoiceCode, setInvoiceCode] = useState("");
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const [receiverCode, setReceiverCode] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
  
    // Access token-ийг header-д ашиглана
    
  
    // Нэхэмжлэх үүсгэх функц
    const createInvoice = async () => {
      const invoiceData = {
        invoice_code: invoiceCode,
        sender_invoice_no: "123456789", // Та өөрийнхөө тохирох дугаарыг ашиглана уу
        invoice_receiver_code: receiverCode,
        sender_branch_code: "BRANCH1", // Та өөрийнхөө салбарын код ашиглана уу
        invoice_description: description,
        amount: parseFloat(amount),
        callback_url: "https://omn1club.com/api/v1/auth/v1/register", // Та callback url-ээ оруулна уу
        invoice_receiver_data: {
          register: "123456789", // Та бүртгэлийн дугаараа ашиглана уу
          name: "Receiver Name", // Хүлээн авагчийн нэр
          email: "receiver@example.com", // Хүлээн авагчийн имэйл
          phone: "123456789", // Хүлээн авагчийн утасны дугаар
        },
        lines: [
          {
            tax_product_code: "6401", // НӨАТ-ийн код
            line_description: description,
            line_quantity: "1.00",
            line_unit_price: amount,
          },
        ],
      };
  
      try {
        const response = await axios.post(
          "https://merchant.qpay.mn/v2/invoice", 
          invoiceData,
          {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response)
  
        if (response.status === 200) {
          setSuccess("Нэхэмжлэл амжилттай үүслээ!");
          setError("");
        }
      } catch (err) {
        setSuccess("");
        setError("Нэхэмжлэх үүсгэхэд алдаа гарлаа: " + err.message);
      }
    };



        if(isLoading) return <LOADING/>

    return (
        <div className={css.containerFluid}>
            {
                success 
            }

            {
                error
            }
            <div className={css.exitContainer}>
                <img src={exit} className={css.exit} onClick={()=>{props.setModal(false)}}/>    
            </div>
            <p className={css.title}>БҮРТГҮҮЛЭХ</p>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={user} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нэвтрэх нэр" className={css.input} onChange={(e) => {setUsername(e.target.value)
                                                                                                     setErrorMessage("")
                }}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={emailIcon} className={css.icon}/>
                </div>
                <input type="text" placeholder="И-Мейл хаяг" className={css.input} onChange={(e)=>{setEmail(e.target.value)
                                                                                                    setErrorMessage("")
                }}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={passwordIcon} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нууц үг" className={css.input} onChange={(e)=> {setPassword(e.target.value)
                                                                                                setErrorMessage("")
                }}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={passwordIcon} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нууц үг давтах" className={css.input} onChange={(e)=>{setConfirmPassword(e.target.value)
                                                                                                      setErrorMessage("")   
                }}/> 
                
            </div>
                <p className={css.errorMessage}>{errorMessage}</p>
            <button className={css.buttonRegister} onClick={handleRegister}>Бүртгүүлэх</button>
            <button className={css.buttonRegister} onClick={handleToken}>QPAY Токен авах</button>
            <button className={css.buttonRegister} onClick={createInvoice}>QPAY Нэхэмжлэл авахss</button>
            <p className={css.bottomTxt}>Бүртгэлтэй юу тэгвэл <label className={css.linkLogin} onClick={()=>{props.setIsLogin("login")}}>энд</label> дарна уу</p>

        </div>
    )
}

export default CONTAINER_REGISTER